import { AbsoluteCenter, Box, Button, Card, CardBody, CardFooter, CardHeader, Center, Divider, Flex, Heading, HStack, Text, useBreakpointValue } from "@chakra-ui/react";
import { useEffect, useState } from "react";

export interface PopoverLevelFinishedProps{
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
    onPopoverAccepted: () => void
    onPopoverRejected: () => void
};

const messages = [
"Engineers: solving problems you didn't know you had in ways you can't understand.",
"Engineers: the only people who can argue over which type of calculator is superior.",
"Engineers don't get lost; they simply find alternative destinations.",
"Engineers don’t have trust issues; they have verification procedures.",
"Mechanical engineers don't break things; they just conduct rigorous testing.",
"Engineers don't daydream; they perform 'mental simulations.'",
"The engineer's idea of \"getting wild\" on the weekend is running a stress test on their coffee maker.",
"No one trusts the simulation, except the guy who did it. Everyone trusts the experiment, except the guy who did it.",
"When an engineer says something's \"simple,\" prepare for an explanation involving calculus.",
"An engineer doesn’t procrastinate—they just call it \"waiting for optimal conditions.\"",
"Engineers don’t make mistakes; they simply have \"unintended test results.\"",
"Running simulations: because breaking things in real life is expensive.",
"Finite element analysis: turning stress into colorful pictures since forever.",
"Simulation: Where everything works—until you press 'run.'",
"Simulations: Where everything looks stable, right up until it doesn’t.",
"FEM: Making engineers feel like artists, one rainbow-colored stress plot at a time.",
"Simulations: Where the computer gets to do all the work… after 50 hours of setup.",
"Engineering meetings are where we discuss the precise and mathematical reasons why the project is 97% behind schedule.",
"You see, the beauty of engineering is that when something goes horribly wrong, we simply call it ‘stress testing’ and everyone applauds.",
"Of course it works! Now, whether or not it explodes is an entirely different matter.",
"Ah, finite elements! Because why deal with one complicated problem when you can split it into 10,000 simpler but equally confusing ones?",
"The finite element method: Proof that even the tiniest triangle can carry the weight of your problems. Until it collapses, that is.",
];

export const PopoverLevelFinished = ({
    isOpen,
    setIsOpen,
    onPopoverAccepted,
    onPopoverRejected
} : PopoverLevelFinishedProps) => {

    const [message, setMessage] = useState<string>("");

    const textSize = useBreakpointValue({base: 'md', lg: 'lg'});
    const buttonSize = useBreakpointValue({base: 'xs', lg: 'lg'});

    useEffect(() => {
        if(isOpen){
            const messageIndex = Math.floor(Math.random() * messages.length);
            setMessage(messages[messageIndex]);
        }
    },[isOpen]);
    return (
        <>
        {
                isOpen &&
                <Box display="flex" width="100%" height="100%" position="absolute" top="0" left="0" zIndex="11" alignItems="center" justifyContent="center">
                        <Card
                            boxShadow="lg"
                            borderRadius="md"
                            p="4"
                            zIndex="10"
                            background="white"
                        >
                            <CardHeader>
                                <Heading size="sm">Good job!</Heading>
                            </CardHeader>
                            <CardBody>
                                <Flex direction="row" gap="2">
                                    <Button colorScheme="blue" size={buttonSize} flex="1" onClick={() => {
                                        onPopoverAccepted();
                                    }}>Next</Button>
                                    <Button size={buttonSize} flex="1" onClick={() => {
                                        onPopoverRejected();
                                    }}>Repeat</Button>
                                </Flex>
                            </CardBody>
                            <Divider />
                            <CardFooter>
                                <Center>
                                    <Text
                                        fontStyle="italic"
                                        fontSize={textSize}
                                        color="gray.600"
                                        mb={4}
                                        textAlign="left"
                                        px={4}
                                        borderLeft="4px solid"
                                        borderColor="gray.300"
                                    >
                                        {message}
                                    </Text>
                                </Center>
                            </CardFooter>
                        </Card>
                </Box>
            }
        </>
    );
};