import { Box, Center, HStack, Tag, Text, useBreakpointValue, VStack } from "@chakra-ui/react";

export interface ColobarProps {
    colors: Array<string>
};

export function Colorbar(
    {
        colors
    }: ColobarProps
) {
    const textSize = useBreakpointValue({ base: 'xs', lg: 'lg' });
    return (
        <>
                <Box flex="1" width="100%" height="100%" position="absolute">
                    <HStack height="100%" gap="0">
                        {
                            colors.map((color) =>
                                <Box bg={color} flex="1" height="100%" />
                            )
                        }
                    </HStack>
                </Box>
                <Box flex="1" width="100%" height="100%" position="absolute">
                    <HStack height="100%" width="100%">
                        <Box flex="4"><Center><Tag opacity="0.8" fontSize={textSize}>&larr; Compression &mdash;</Tag></Center></Box>
                        <Box flex="1"><Center><Tag opacity="0.8" fontSize={textSize}>0</Tag></Center></Box>
                        <Box flex="4"><Center><Tag opacity="0.8" fontSize={textSize}>&mdash; Tension &rarr;</Tag></Center></Box>
                    </HStack>
                </Box>
        </>
    );
}