import { useBreakpointValue } from '@chakra-ui/react';
import { useRef, useCallback, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';

export function useAdminMode() {
    const [isAdminMode, setIsAdminMode] = useState(true);
    const disableAdminMode = useBreakpointValue({base: true, md: false}, {ssr: false});

    useEffect(() => {
        if(disableAdminMode === true){
            setIsAdminMode(false);
        }
        const handleKeyDown = (event: KeyboardEvent) => {
            if(event.altKey && event.shiftKey && event.key.toLowerCase() === 'd'){
                setIsAdminMode((prev) => !prev);
            }
        }

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    },[disableAdminMode]);

    return isAdminMode;
}